import { useMemo } from "react";

import Portal from "@sellernote/_shared/src/components/Portal";
import { useOverlayStack } from "@sellernote/_shared/src/context/overlayStack";
import useModal, {
  ModalPropsV2,
} from "@sellernote/_shared/src/headlessComponents/useModal";
import { getTargetComponentFromChildren } from "@sellernote/_shared/src/utils/common/etc";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";

import { COLOR } from "../../../styles/colors";

import Button from "../../button/Button";
import ProcessStep from "../ProcessStep";
import ActionOptionButton from "./ActionOptionButton";
import Styled from "./index.styles";

function Modal({
  active,
  onClose,
  needConfirmBeforeCloseModal,
  className,
  children,
  usePortal = true,
  ...propsByType
}: ModalPropsV2) {
  const {
    handleModalClose,
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  } = useModal({
    active,
    onClose,
    needConfirmBeforeCloseModal,
  });

  useOverlayStack({ isActive: active, onClose });

  const ProcessStepComponent = getTargetComponentFromChildren(
    children,
    <ProcessStep activeStepNumber={1} />
  );

  const Modal = useMemo(() => {
    return (
      <Styled.container
        className={`${className ? className : ""} modal`}
        onClick={(e) => e.stopPropagation()}
      >
        <Styled.modalContainer
          uiType={propsByType.uiType}
          width={
            propsByType.uiType === "formInput" ? propsByType.width : undefined
          }
          height={
            propsByType.uiType === "formInput" ? propsByType.height : undefined
          }
          maxWidth={
            propsByType.uiType === "formInput"
              ? propsByType.maxWidth
              : undefined
          }
          className="modal-container"
        >
          {(propsByType.uiType === "titleAndBody" ||
            propsByType.uiType === "titleAndDescAndBody") &&
            propsByType.title && (
              <Styled.title>{propsByType.title}</Styled.title>
            )}

          {propsByType.uiType === "formInput" && propsByType.title && (
            <Styled.title isFormInput hasDesc={!!propsByType.desc}>
              {propsByType.title}

              {propsByType.desc && (
                <Styled.descContainer isFormInput>
                  {propsByType.desc}
                </Styled.descContainer>
              )}
            </Styled.title>
          )}

          {propsByType.uiType === "iconAndBody" && (
            <propsByType.Icon
              width={40}
              height={40}
              color={COLOR.bk}
              className="head-icon"
            />
          )}

          {(propsByType.uiType === "onlyDescription" ||
            propsByType.uiType === "titleAndDescAndBody") &&
            propsByType.desc && (
              <Styled.descContainer>{propsByType.desc}</Styled.descContainer>
            )}

          {propsByType.uiType !== "onlyDescription" && propsByType.body && (
            <Styled.bodyContainer
              className="body-container"
              isFormInput={propsByType.uiType === "formInput"}
            >
              {propsByType.body}
            </Styled.bodyContainer>
          )}

          {(propsByType.actionPositive ||
            propsByType.actionNegative ||
            propsByType.actionOption) && (
            <Styled.actionContainer uiType={propsByType.uiType}>
              <div>
                {propsByType.actionOption && (
                  <ActionOptionButton
                    actionOptionProps={propsByType.actionOption}
                  />
                )}
              </div>

              <div className="positive-negative-container">
                {propsByType.actionNegative && (
                  <Button
                    label={propsByType.actionNegative.label}
                    handleClick={(e) => {
                      if (propsByType.actionNegative) {
                        propsByType.actionNegative.handleClick();
                        e.stopPropagation();
                      }
                    }}
                    borderType={
                      propsByType.actionNegative.borderType
                        ? propsByType.actionNegative.borderType
                        : "outlined"
                    }
                    theme={
                      propsByType.actionNegative.isCritical
                        ? "critical"
                        : propsByType.actionNegative.isSecondary
                        ? "secondary"
                        : "tertiary"
                    }
                    size="normal"
                    iconInfo={propsByType.actionNegative.iconInfo}
                    disabled={propsByType.actionNegative.disabled}
                  />
                )}

                {propsByType.actionPositive && (
                  <Button
                    label={propsByType.actionPositive.label}
                    handleClick={(e) => {
                      if (propsByType.actionPositive) {
                        propsByType.actionPositive.handleClick();
                        e.stopPropagation();
                      }
                    }}
                    borderType={
                      propsByType.actionPositive.borderType
                        ? propsByType.actionPositive.borderType
                        : "filled"
                    }
                    theme={
                      propsByType.actionPositive.isCritical
                        ? "critical"
                        : "primary"
                    }
                    size="normal"
                    disabled={propsByType.actionPositive.disabled}
                    iconInfo={propsByType.actionPositive.iconInfo}
                  />
                )}
              </div>
            </Styled.actionContainer>
          )}

          {onClose && (
            <XMarkDefaultIcon
              width={24}
              height={24}
              color={COLOR.bk}
              className="clear-button"
              onClick={handleModalClose}
            />
          )}

          {ProcessStepComponent && ProcessStepComponent}
        </Styled.modalContainer>
      </Styled.container>
    );
  }, [ProcessStepComponent, className, handleModalClose, onClose, propsByType]);

  if (!active) return null;

  if (usePortal) {
    return <Portal selector="#app-portal">{Modal}</Portal>;
  }

  return Modal;
}

export default Modal;
Modal.ProcessStep = ProcessStep;
