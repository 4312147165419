import { ReactNode } from "react";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  Driver,
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import {
  BidItem,
  BLTypeInfo,
  InspectionStatus,
  ReceivingDelivery,
  ReceivingInspectItem,
  ReceivingInvoiceNo,
  ReceivingKind,
  ReceivingMemo,
  ReceivingStatus,
} from "@sellernote/_shared/src/types/fulfillment/receiving";

import { BidTransportType } from "../../types/forwarding/bid";
import {
  ReceivingItem,
  ReceivingPlaceItem,
} from "../../types/fulfillment/receiving";

import { formatDate, getRemainedDays } from "../common/date";
import { getTitleAmongItemListName } from "../forwarding/bid";
import { toParcelCompanyLabel } from "./fulfillment";

/**
 * 입고 ID를 포맷팅하여 반환
 *
 * @param receiving - 입고 정보 객체
 * @returns 포맷팅된 입고 ID 문자열
 */
const getFormattedReceivingId = (
  receiving:
    | {
        bidId?: number;
        id?: number;
      }
    | undefined
) => {
  if (!receiving) {
    return "";
  }

  return receiving.bidId || `A${receiving.id}`;
};

/**
 * 초기값으로 만들어진 분할입고만 가지고 있는지 여부를 체크
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 초기값으로 만들어진 분할입고만 있는지 여부
 */
function checkHasOnlyInitialWarehousing(receivingItem?: ReceivingItem) {
  return (
    receivingItem?.placeItems?.length === 1 &&
    receivingItem.placeItems[0].quantity === receivingItem.actualQty &&
    !receivingItem.placeItems[0].placeQty
  );
}

/**
 * 초기값으로 만들어진 분할검수만 가지고 있는지 여부를 체크
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 초기값으로 만들어진 분할검수만 있는지 여부
 */
function checkHasOnlyInitialInspection(receivingItem?: ReceivingItem) {
  return (
    receivingItem?.inspectItems?.length === 1 &&
    receivingItem.inspectItems[0].quantity === receivingItem.quantity &&
    !receivingItem.inspectItems[0].actualQty
  );
}

/**
 * 분할입고인지 체크
 *
 * @param actualQty - 실제 수량
 * @param placeItems - 입고 장소 아이템 배열
 * @returns 분할입고 여부
 */
function getHasMultiLocationWarehousing(
  actualQty?: number,
  placeItems?: ReceivingPlaceItem[]
) {
  if (!placeItems) return false;

  // 1개이더라도 모든 입고양이 할당되지 않았다면 분할입고로 간주
  if (placeItems.length === 1 && placeItems[0].quantity < (actualQty ?? 0)) {
    return true;
  }

  return placeItems.length > 1;
}

/**
 * 분할검수인지 체크
 *
 * @param quantity - 수량
 * @param inspectItems - 검수 아이템 배열
 * @returns 분할검수 여부
 */
function getHasMultiLocationInspection(
  quantity?: number,
  inspectItems?: ReceivingInspectItem[]
) {
  if (!inspectItems) return false;

  // 1개이더라도 모든 수량이 할당되지 않았다면 분할검수로 간주
  if (inspectItems.length === 1 && inspectItems[0].quantity < (quantity ?? 0)) {
    return true;
  }

  return inspectItems.length > 1;
}

/**
 * 잔여 입고량을 반환
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 잔여 입고량
 */
function getRemainedQtyForWarehousing(receivingItem: ReceivingItem) {
  if (!receivingItem.placeItems) return 0;

  const hasOnlyInitialWarehousing =
    checkHasOnlyInitialWarehousing(receivingItem);

  const max = receivingItem.actualQty ?? 0;

  if (hasOnlyInitialWarehousing) {
    // 초기값으로 만들어진 분할입고에서는 잔여 입고량과 상관없이 다시 분할 가능함
    return max;
  }

  const partedQuantityTotal = receivingItem.placeItems.reduce((a, c) => {
    return a + (c.quantity ?? 0);
  }, 0);

  return max - partedQuantityTotal;
}

/**
 * 잔여 검수량을 반환
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 잔여 검수량
 */
function getRemainedQtyForInspection(receivingItem: ReceivingItem) {
  if (!receivingItem.inspectItems) return 0;

  const hasOnlyInitialInspection = checkHasOnlyInitialInspection(receivingItem);

  const max = receivingItem.quantity ?? 0;

  if (hasOnlyInitialInspection) {
    // 초기값으로 만들어진 분할검수에서는 잔여 검수량과 상관없이 다시 분할 가능함
    return max;
  }

  const partedQuantityTotal = receivingItem.inspectItems.reduce((a, c) => {
    return a + (c.quantity ?? 0);
  }, 0);

  return max - partedQuantityTotal;
}

/**
 * 각 아이템 총 합을 반환
 *
 * @param items - 수량을 가진 아이템 배열
 * @returns 아이템 총 합
 */
function getTotalPCS(items?: { quantity: number }[]) {
  if (!items) return 0;

  return items.reduce((p, c) => {
    return p + c.quantity;
  }, 0);
}

/**
 * 검수 상태 문자열을 반환
 *
 * @param status - 검수 상태
 * @returns 검수 상태 문자열
 */
function getInspectionStatusString(status: InspectionStatus) {
  switch (status) {
    case "normal": {
      return "입고(정상)";
    }
    case "consent": {
      return "입고(동의)";
    }
    case "hold": {
      return "보류";
    }
    case "returned": {
      return "회송";
    }
    default: {
      return status;
    }
  }
}

/**
 * 도메인 타입에 따른 사진 타입 라벨을 반환
 *
 * @param domainType - 파일 도메인 타입
 * @returns 사진 타입 라벨
 */
function getPictureTypeLabelByDomainType(domainType: FileDomainType) {
  switch (domainType) {
    case "cargo": {
      return "컨테이너 및 씰넘버 (화물차량 및 번호)";
    }
    case "invoice": {
      return "송장스캔";
    }
    case "packing": {
      return "패킹사진";
    }
    case "damages": {
      return "데미지";
    }
    case "shippingReceipt": {
      return "출고증";
    }
    default: {
      return "";
    }
  }
}

/**
 * 도메인 타입에 따른 사진 리스트를 반환
 *
 * @param attachmentList - 첨부 파일 리스트
 * @param domainType - 파일 도메인 타입
 * @returns 필터링된 사진 리스트
 */
function getPictureListByDomainType(
  attachmentList: FulfillmentAttachment[],
  domainType: FileDomainType
) {
  if (!attachmentList.length) {
    return [];
  }

  return attachmentList.filter((v) => v.domain === domainType);
}

/**
 * 입고 상태 타이틀을 반환
 *
 * @param status - 입고 상태
 * @returns 입고 상태 타이틀
 */
function getStatusTitle(status: ReceivingStatus) {
  switch (status) {
    // TODO: 일단 2개밖에 안 써서 이것만 추가함.
    case "hold": {
      return "보류";
    }
    case "done": {
      return "입고완료";
    }
    default: {
      return "";
    }
  }
}

/**
 * 운송방식 label을 반환
 *
 * @param freightType - 화물 타입
 * @param delivery - 입고 방식
 * @param invoiceNo - 송장 번호
 * @param noNeedTransportNumber - 운송 번호가 필요 없는 경우
 * @returns 운송방식 라벨
 */
function getTransportMethodTitle({
  freightType,
  delivery,
  invoiceNo,
  noNeedTransportNumber,
}: {
  freightType?: FreightType;
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  /**
   * 택배 번호등의 정보가 결합되지 않고 없이 운송방식 타입만 필요한 경우에 사용
   */
  noNeedTransportNumber?: boolean;
}) {
  if (freightType) {
    switch (freightType) {
      case "FCL": {
        return "컨테이너";
      }
      case "AIR":
      case "LCL": {
        return "화물차량";
      }
    }
  }

  if (delivery) {
    switch (delivery) {
      case "parcel": {
        return noNeedTransportNumber ? "택배" : `택배(${invoiceNo || "-"})`;
      }
      case "truck":
      case "truckRequest": {
        return "화물차량";
      }
    }
  }

  return "";
}

/**
 * 입고방식 label을 반환
 *
 * @param delivery - 입고 방식
 * @returns 입고방식 라벨
 */
function getReceivingDeliveryTitle(delivery?: ReceivingDelivery) {
  switch (delivery) {
    case "parcel": {
      return "직접입고(택배)";
    }
    case "truck": {
      return "직접입고(화물차량)";
    }
    case "truckRequest": {
      return "배차요청(화물차량)";
    }
    default: {
      return "";
    }
  }
}

/**
 * 해당 입고 item이 일반입고(cf. 분할입고)로 확정되었는지 체크
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 일반입고로 확정되었는지 여부
 */
function checkConfirmedAsSingleLocationWarehousing(receivingItem?: {
  placeItems: ReceivingPlaceItem[];
  actualQty?: number;
}): boolean {
  if (!receivingItem) {
    return false;
  }

  const result =
    receivingItem.placeItems?.length === 1 &&
    receivingItem.placeItems[0].quantity === receivingItem.actualQty &&
    !!receivingItem.placeItems[0].placerId;

  return result;
}

/**
 * 해당 입고 item이 일반검수(cf. 분할검수)로 확정되었는지 체크
 *
 * @param receivingItem - 입고 아이템 객체
 * @returns 일반검수로 확정되었는지 여부
 */
function checkConfirmedAsSingleLocationInspection(
  receivingItem?: ReceivingItem
): boolean {
  if (!receivingItem) {
    return false;
  }

  const result =
    receivingItem.inspectItems?.length === 1 &&
    receivingItem.inspectItems[0].quantity === receivingItem.quantity &&
    !!receivingItem.inspectItems[0].inspectorId;

  return result;
}

/**
 * 입고 아이템의 제목을 반환
 *
 * @param items - SKU 정보를 가진 아이템 배열
 * @returns 입고 아이템 제목
 */
function getReceivingItemTitle({
  items,
}: {
  items: { sku?: { itemName?: string } }[] | undefined;
}) {
  if (!items || !items.length) {
    return "";
  }

  const itemNameList = items.map((v) => {
    return { name: v.sku?.itemName ?? "" };
  });

  return getTitleAmongItemListName(itemNameList, 10);
}

/**
 * 출발 지점 이름을 반환
 *
 * @param isOverseas - 해외 여부
 * @param startType - 출발 타입
 * @param startCountry - 출발 국가 코드
 * @param getCountryNameFn - 국가 이름을 반환하는 함수
 * @returns 출발 지점 이름
 */
function getReceivingStartPointName({
  isOverseas,
  startType,
  startCountry,
  getCountryNameFn,
}: {
  isOverseas: boolean;
  startType: BidTransportType;
  startCountry: string;
  getCountryNameFn: (countryCode?: string | undefined) => string;
}) {
  if (!isOverseas) return "국내 내륙";

  if (isOverseas) {
    switch (startType) {
      case "air":
        return `${getCountryNameFn(startCountry)} 공항`;
      case "sea":
        return `${getCountryNameFn(startCountry)} 항구`;
      case "inland":
        return `${getCountryNameFn(startCountry)} 내륙`;
    }
  }
}

/**
 * 운송타입에 따른 운송관련 번호를 반환
 * - 차량 : 차량번호
 * - 택배 : 택배 송장번호
 * - 수입의 경우 B/L 번호
 *
 * @param receivingKind - 입고 종류
 * @param delivery - 입고 방식
 * @param invoiceNo - 송장 번호
 * @param BL - B/L 번호
 * @param driver - 운전사 정보
 * @returns 운송관련 번호
 */
function getTransportNumber({
  receivingKind,
  delivery,
  invoiceNo,
  BL,
  driver,
}: {
  receivingKind: ReceivingKind;
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  BL?: string;
  driver?: Driver;
}) {
  if (receivingKind === "import") return BL || "-";

  if (delivery === "parcel") {
    return invoiceNo || "-";
  }

  if (delivery === "truck") {
    return driver?.truckNo || "-";
  }

  return "";
}

/**
 * 택배/운송차량에 관한 정보를 반환
 *
 * @param delivery - 입고 방식
 * @param invoiceNo - 송장 번호
 * @param driver - 운전사 정보
 * @returns 택배/운송차량 정보
 */
function getTransportDeliveryNumber({
  delivery,
  invoiceNo,
  driver,
}: {
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  driver?: Driver;
}) {
  if (delivery === "parcel") {
    return invoiceNo || "-";
  }

  if (delivery === "truck") {
    return driver?.truckNo || "-";
  }

  return "-";
}

/**
 * 송장 번호 라벨을 생성
 *
 * @param invoiceNo - 송장 번호 배열
 * @returns 송장 번호 라벨 배열
 */
function makeInvoiceNoLabels(invoiceNo: ReceivingInvoiceNo[] | undefined) {
  if (!invoiceNo || !invoiceNo.length) {
    return [];
  }

  return invoiceNo.map(
    (v) => `${v.invoiceNo || "-"}(${toParcelCompanyLabel(v.parcelCompany)})`
  );
}

/**
 * 표시할 송장 번호를 생성
 *
 * @param invoiceNo - 송장 번호 배열
 * @param isForCSV - CSV용 여부
 * @returns 표시할 송장 번호 문자열
 */
function makeInvoiceNoToDisplay(
  invoiceNo: ReceivingInvoiceNo[] | undefined,
  isForCSV?: boolean
) {
  if (!invoiceNo || !invoiceNo.length) return "";

  const invoiceNoLabels = makeInvoiceNoLabels(invoiceNo);

  if (isForCSV) {
    return invoiceNoLabels.join(", ");
  }

  return invoiceNoLabels.length === 1
    ? invoiceNoLabels[0]
    : `${invoiceNoLabels[0]} 외 ${invoiceNoLabels.length - 1}건`;
}

/**
 * 총 포장 단위를 반환
 *
 * @param packingUnitPallet - 팔레트 단위
 * @param packingUnitBox - 박스 단위
 * @param packingUnitOther - 기타 단위
 * @returns 총 포장 단위 문자열
 */
function getTotalPackingUnit({
  packingUnitPallet,
  packingUnitBox,
  packingUnitOther,
}: {
  packingUnitPallet?: number;
  packingUnitBox?: number;
  packingUnitOther?: number;
}) {
  const pallet = !!packingUnitPallet && `${packingUnitPallet} 팔레트`;
  const box = !!packingUnitBox && `${packingUnitBox} 박스`;
  const other = !!packingUnitOther && `${packingUnitOther} 기타`;

  return [pallet, box, other].filter((v) => !!v).join(", ");
}

/**
 * '3.33 CBM / 8,0000 kgs' 형태의 데이터를 분리해서 표시
 * 둘 다 존재하거나 kgs만 존재하는 경우 2가지로 나뉨
 *
 * @param invoiceCbm - CBM 및 무게 문자열
 * @returns 분리된 CBM 및 무게 객체
 */
const makeCbmAndWeight = (
  invoiceCbm: string | undefined
): { cbm: string; weight: string } => {
  if (!invoiceCbm) {
    return { cbm: "", weight: "" };
  }

  const splitCbm = invoiceCbm.split(" / ");

  const hasCbm = splitCbm.length === 2;

  const cbm = hasCbm ? splitCbm[0] : "";
  const weight = hasCbm ? splitCbm[1] : splitCbm[0];

  return { cbm, weight };
};

/**
 * 어드민 > 입고관리 > 확정수량
 *
 * @param placeQty - 확정 수량
 * @param status - 입고 상태
 * @returns 확정 수량 문자열
 */
const getAdminPlaceQty = ({
  placeQty,
  status,
}: {
  placeQty: number | null | undefined;
  status: ReceivingStatus;
}) => {
  const isInspectionStep = !placeQty;
  const isWarehousingProblemReportStep = status === "hold";
  const isWarehousingDoneStep = status === "done";

  if (isInspectionStep || isWarehousingProblemReportStep) {
    return "-";
  }

  if (isWarehousingDoneStep) {
    return placeQty;
  }

  return "-";
};

/**
 * 입고 종류 라벨을 반환
 *
 * @param receivingKind - 입고 종류
 * @returns 입고 종류 라벨
 */
const getReceivingKindLabel = (receivingKind: ReceivingKind) =>
  receivingKind === "import" ? "수입" : "국내";

/**
 * 어드민 남은 일수를 반환
 *
 * @param dueDate - 마감일
 * @returns 남은 일수 문자열
 */
const getAdminRemainedDays = (dueDate: string | undefined) => {
  if (!dueDate) {
    return "-";
  }

  const remainedDays = getRemainedDays(new Date(), dueDate);
  const hasRemainedDays = remainedDays >= 0;
  if (hasRemainedDays) {
    return `${remainedDays}일`;
  }

  return "기한초과";
};

/**
 * 총 혼합 수량을 반환
 *
 * @param items - 입고 아이템 배열
 * @returns 총 혼합 수량
 */
const getTotalMixQty = (items: ReceivingItem[] | undefined) => {
  if (!items) {
    return 0;
  }

  return items.reduce((totalMixQty, item) => totalMixQty + item.mixQty || 0, 0);
};

/**
 * 어드민 운송 번호를 반환
 *
 * @param isForCSV - CSV용 여부
 * @param invoiceNo - 송장 번호 배열
 * @param transportNumber - 운송 번호
 * @param TransportNumberWithTooltip - 툴팁을 포함한 운송 번호
 * @returns 어드민 운송 번호
 */
const getAdminTransportNumber = ({
  isForCSV,
  invoiceNo,
  transportNumber,
  TransportNumberWithTooltip,
}: {
  isForCSV: boolean | undefined;
  invoiceNo: ReceivingInvoiceNo[] | undefined;
  transportNumber: string;
  TransportNumberWithTooltip: ReactNode;
}) => {
  const needsToolTip = (invoiceNo?.length ?? 0) > 1;

  if (isForCSV || !needsToolTip) {
    return transportNumber;
  }

  return TransportNumberWithTooltip;
};

/**
 * 어드민 문제를 반환
 *
 * @param items - 입고 아이템 배열
 * @param ProblemsButton - 문제 버튼
 * @returns 어드민 문제
 */
const getAdminProblems = ({
  items,
  ProblemsButton,
}: {
  items: ReceivingItem[] | undefined;
  ProblemsButton: ReactNode;
}) => {
  if (!items || !items.length) {
    return "";
  }

  const hasProblem = items.some((item) => {
    const hasInspectionProblem = item.inspectionProblem !== "none";
    const hasReceivingProblem = item.receivingProblem !== "none";

    return hasInspectionProblem || hasReceivingProblem;
  });
  if (!hasProblem) {
    return "";
  }

  return ProblemsButton;
};

/**
 * 어드민 메모를 반환
 *
 * @param memo - 메모 배열
 * @param MemoButton - 메모 버튼
 * @returns 어드민 메모
 */
const getAdminMemo = ({
  memo,
  MemoButton,
}: {
  memo: ReceivingMemo[] | undefined;
  MemoButton: ReactNode;
}) => {
  if (!memo || !memo.length) {
    return "";
  }

  return MemoButton;
};

/**
 * B/L 번호를 반환
 *
 * @param bid - 견적 정보 객체
 * @param needsAsString - 문자열로 반환할지 여부
 * @returns B/L 번호
 */
function getBLNumber<T extends boolean>(
  bid: { BLType: BLTypeInfo; hBL?: string; mBL?: string } | undefined,
  needsAsString?: T
): T extends true ? string : ReactNode {
  if (!bid) {
    return "-";
  }

  const { BLType, hBL, mBL } = bid;

  if (!BLType) {
    return "-";
  }

  if (BLType === "HBL") {
    return hBL ?? "-";
  }

  if (BLType === "MBL" || BLType === "DirectMBL") {
    return mBL ?? "-";
  }

  if (BLType === "ALL") {
    if (!needsAsString) {
      <>
        {hBL}
        <br /> {mBL}
      </>;
    }

    return `${hBL} / ${mBL}`;
  }

  return "-";
}
/**
 * 견적 아이템의 제목을 반환
 *
 * @param items - 견적 아이템 배열
 * @param maxLength - 최대 길이
 * @returns 견적 아이템 제목
 */
function getBidTitle({
  items,
  maxLength,
}: {
  items: BidItem[] | undefined;
  maxLength?: number;
}) {
  if (!items || !items.length) {
    return "";
  }

  return getTitleAmongItemListName(items, maxLength);
}

/**
 * 도착예정일시 또는 입고예정일자를 포맷팅하거나 수입신고 수리 전 상태를 노출
 *
 * @param receiving - 입고 정보 객체
 * @param formatTypeForShipda - 화주웹 포맷팅 타입 (date 또는 time)
 * @returns 포맷팅된 날짜 문자열 또는 수입신고 수리 전 상태 문자열
 */
const getReceivingExpectedDate = (
  receiving:
    | {
        receivingKind: ReceivingKind;
        /** 도착예정일시 */
        expectedDate?: string;
        /** 입고예정일자 */
        expectedArrivalDate?: string;
      }
    | undefined,
  /** 화주웹 포맷팅을 별도로 지정해주기 위함 */
  formatTypeForShipda?: "date" | "time"
): string => {
  if (!receiving) {
    return "";
  }

  const { receivingKind, expectedDate, expectedArrivalDate } = receiving;

  if (receivingKind === "import" && !expectedDate) {
    return "수입신고 수리 전";
  }

  // 화주웹의 경우 입고예정일자를 표기함
  if (formatTypeForShipda) {
    const dateFormat =
      formatTypeForShipda === "time" ? "YY_MM_DD_HH_mm" : "YY_MM_DD";

    return formatDate({ date: expectedArrivalDate, type: dateFormat });
  }

  // ADMIN, PDA
  return formatDate({ date: expectedDate, type: "YY_MM_DD" });
};

/**
 * 상품 관리 테이블에서 가로, 세로, 높이를 합쳐주는 함수
 *
 * @param width - 가로 길이
 * @param length - 세로 길이
 * @param height - 높이
 * @returns 합쳐진 가로, 세로, 높이 문자열
 */
const getDimension = ({
  width,
  length,
  height,
}: {
  width: string | number | null;
  length: string | number | null;
  height: string | number | null;
}) => {
  const widthString = width ? `${width}/` : "";
  const lengthString = length ? `${length}/` : "";
  const heightString = height ? `${height}` : "";

  return `${widthString}${lengthString}${heightString}`;
};

export {
  getFormattedReceivingId,
  getTotalPCS,
  getInspectionStatusString,
  getPictureTypeLabelByDomainType,
  getPictureListByDomainType,
  getStatusTitle,
  getTransportMethodTitle,
  getReceivingDeliveryTitle,
  checkConfirmedAsSingleLocationWarehousing,
  checkConfirmedAsSingleLocationInspection,
  checkHasOnlyInitialWarehousing,
  checkHasOnlyInitialInspection,
  getHasMultiLocationWarehousing,
  getHasMultiLocationInspection,
  getRemainedQtyForWarehousing,
  getRemainedQtyForInspection,
  getReceivingItemTitle,
  getReceivingStartPointName,
  getTransportNumber,
  getTransportDeliveryNumber,
  getTotalPackingUnit,
  makeInvoiceNoLabels,
  makeInvoiceNoToDisplay,
  makeCbmAndWeight,
  getAdminPlaceQty,
  getReceivingKindLabel,
  getAdminRemainedDays,
  getTotalMixQty,
  getAdminTransportNumber,
  getAdminProblems,
  getAdminMemo,
  getBLNumber,
  getBidTitle,
  getReceivingExpectedDate,
  getDimension,
};
